<nav id="topnav" class="defaultscroll is-sticky" (window:scroll)="windowScroll()">
    <div class="container relative">
        <!-- Logo container-->
        <a class="logo" routerLink="/">
            <img src="assets/images/logo-light.png" class="inline-block" alt="">
        </a>

        <!-- End Logo container-->
        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <!--Login button Start-->
        <!-- <ul class="buy-button list-none mb-0">
            <li class="inline mb-0">
                <a routerLink="#" class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><i data-feather="user" class="size-4"></i></a>
            </li>
    
            <li class="inline ps-1 mb-0">
                <a href="https://1.envato.market/techwind-angular" target="_blank" class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><i data-feather="shopping-cart" class="size-4"></i></a>
            </li>
        </ul> -->
        <!--Login button End-->

        <div id="navigation" [style]="status  ? 'display: block;' : 'display: none;'">
            <!-- Navigation Menu-->   
            <ul class="navigation-menu">
                <li [ngClass]="activeManu === '/' ? 'active' : ''"><a routerLink="/" class="sub-menu-item">Home</a></li>

                <li><a routerLink="/" class="sub-menu-item" (click)="scrollToLandingSectionId('key-features')">Features</a></li>

                <li><a routerLink="/" class="sub-menu-item" (click)="scrollToLandingSectionId('contact')">Contact</a></li>
        
                <!-- <li class="has-submenu parent-parent-menu-item" [ngClass]="['/index-saas','/index-classic-saas','/index-modern-saas','/index-apps','/index-classic-app','/index-ai', '/index-smartwatch', '/index-marketing','/index-seo', '/index-software','/index-payment','/index-charity','/index-it-solution','/index-it-solution-two', '/index-digital-agency','/index-restaurent','/index-hosting','/index-nft','/index-hotel','/index-travel','/index-cafe','/index-gym','/index-yoga','/index-spa', '/index-job', '/index-startup', '/index-business','/index-corporate','/index-corporate-two', '/index-real-estate', '/index-consulting', '/index-insurance', '/index-construction', '/index-law', '/index-video','/index-christmas','/index-personal', '/index-portfolio', '/index-photography', '/index-studio', '/index-coworking', '/index-classic-business', '/index-course','/index-event', '/index-podcast', '/index-hospital','/index-phone-number', '/index-forums', '/index-shop', '/index-crypto','/index-landing-one','/index-landing-two','/index-landing-three','/index-landing-four','/index-service','/index-food-blog','/index-blog','/index-furniture','/index-landing-five','/index-item','/index-life-coach','/index-landing-six'].includes(activeManu) ? 'active' : ''">
                    <a routerLink="#" (click)="subManu('/index-item')">Landings</a><span class="menu-arrow"></span>

                    <ul class="submenu megamenu" [ngClass]="['/index-saas','/index-classic-saas','/index-modern-saas','/index-apps','/index-classic-app','/index-ai', '/index-smartwatch', '/index-marketing','/index-seo', '/index-software','/index-payment','/index-charity','/index-it-solution','/index-it-solution-two', '/index-digital-agency','/index-restaurent','/index-hosting','/index-nft','/index-hotel','/index-travel','/index-cafe','/index-gym','/index-yoga','/index-spa', '/index-job', '/index-startup', '/index-business','/index-corporate','/index-corporate-two', '/index-real-estate', '/index-consulting', '/index-insurance', '/index-construction', '/index-law', '/index-video','/index-christmas','/index-personal', '/index-portfolio', '/index-photography', '/index-studio', '/index-coworking', '/index-classic-business', '/index-course','/index-event', '/index-podcast', '/index-hospital','/index-phone-number', '/index-forums', '/index-shop', '/index-crypto','/index-landing-one','/index-landing-two','/index-landing-three','/index-landing-four','/index-service','/index-food-blog','/index-blog','/index-furniture','/index-landing-five','/index-item','/index-life-coach','/index-landing-six'].includes(manuOpen) ? 'open' : ''">
                        <li>
                            <ul>
                                <li [ngClass]="activeManu === '/index-saas' ? 'active' : ''"><a routerLink="/index-saas" class="sub-menu-item">Saas <span class="bg-emerald-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Animation</span></a></li>
                                <li [ngClass]="activeManu === '/index-classic-saas' ? 'active' : ''"><a routerLink="/index-classic-saas" class="sub-menu-item">Classic Saas </a></li>
                                <li [ngClass]="activeManu === '/index-modern-saas' ? 'active' : ''"><a routerLink="/index-modern-saas" class="sub-menu-item">Modern Saas </a></li>
                                <li [ngClass]="activeManu === '/index-apps' ? 'active' : ''"><a routerLink="/index-apps" class="sub-menu-item">Application</a></li>
                                <li [ngClass]="activeManu === '/index-classic-app' ? 'active' : ''"><a routerLink="/index-classic-app" class="sub-menu-item">Classic App </a></li>
                                <li [ngClass]="activeManu === '/index-ai' ? 'active' : ''"><a routerLink="/index-ai" class="sub-menu-item">AI Tools <span class="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></a></li>
                                <li [ngClass]="activeManu === '/index-smartwatch' ? 'active' : ''"><a routerLink="/index-smartwatch" class="sub-menu-item">Smartwatch</a></li>
                                <li [ngClass]="activeManu === '/index-marketing' ? 'active' : ''"><a routerLink="/index-marketing" class="sub-menu-item">Marketing</a></li>
                                <li [ngClass]="activeManu === '/index-seo' ? 'active' : ''"><a routerLink="/index-seo" class="sub-menu-item">SEO Agency </a></li>
                                <li [ngClass]="activeManu === '/index-software' ? 'active' : ''"><a routerLink="/index-software" class="sub-menu-item">Software </a></li>
                                <li [ngClass]="activeManu === '/index-payment' ? 'active' : ''"><a routerLink="/index-payment" class="sub-menu-item">Payments</a></li>
                                <li [ngClass]="activeManu === '/index-charity' ? 'active' : ''"><a routerLink="/index-charity" class="sub-menu-item">Charity </a></li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li [ngClass]="activeManu === '/index-it-solution' ? 'active' : ''"><a routerLink="/index-it-solution" class="sub-menu-item">IT Solution</a></li>
                                <li [ngClass]="activeManu === '/index-it-solution-two' ? 'active' : ''"><a routerLink="/index-it-solution-two" class="sub-menu-item">It Solution Two </a></li>
                                <li [ngClass]="activeManu === '/index-digital-agency' ? 'active' : ''"><a routerLink="/index-digital-agency" class="sub-menu-item">Digital Agency</a></li>
                                <li [ngClass]="activeManu === '/index-restaurent' ? 'active' : ''"><a routerLink="/index-restaurent" class="sub-menu-item">Restaurent</a></li>
                                <li [ngClass]="activeManu === '/index-hosting' ? 'active' : ''"><a routerLink="/index-hosting" class="sub-menu-item">Hosting</a></li>
                                <li [ngClass]="activeManu === '/index-nft' ? 'active' : ''"><a routerLink="/index-nft" class="sub-menu-item">NFT Marketplace <span class="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></a></li>
                                <li [ngClass]="activeManu === '/index-hotel' ? 'active' : ''"><a routerLink="/index-hotel" class="sub-menu-item">Hotel & Resort</a></li>
                                <li [ngClass]="activeManu === '/index-travel' ? 'active' : ''"><a routerLink="/index-travel" class="sub-menu-item">Travels </a></li>
                                <li [ngClass]="activeManu === '/index-cafe' ? 'active' : ''"><a routerLink="/index-cafe" class="sub-menu-item">Cafe <span class="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></a></li>
                                <li [ngClass]="activeManu === '/index-gym' ? 'active' : ''"><a routerLink="/index-gym" class="sub-menu-item">Gym <span class="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></a></li>
                                <li [ngClass]="activeManu === '/index-yoga' ? 'active' : ''"><a routerLink="/index-yoga" class="sub-menu-item">Yoga </a></li>
                                <li [ngClass]="activeManu === '/index-spa' ? 'active' : ''"><a routerLink="/index-spa" class="sub-menu-item">Spa & Salon </a></li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li [ngClass]="activeManu === '/index-job' ? 'active' : ''"><a routerLink="/index-job" class="sub-menu-item">Job <span class="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></a></li>
                                <li [ngClass]="activeManu === '/index-startup' ? 'active' : ''"><a routerLink="/index-startup" class="sub-menu-item">Startup</a></li>
                                <li [ngClass]="activeManu === '/index-business' ? 'active' : ''"><a routerLink="/index-business" class="sub-menu-item">Business</a></li>
                                <li [ngClass]="activeManu === '/index-corporate' ? 'active' : ''"><a routerLink="/index-corporate" class="sub-menu-item">Corporate</a></li>
                                <li [ngClass]="activeManu === '/index-corporate-two' ? 'active' : ''"><a routerLink="/index-corporate-two" class="sub-menu-item">Corporate Two </a></li>
                                <li [ngClass]="activeManu === '/index-real-estate' ? 'active' : ''"><a routerLink="/index-real-estate" class="sub-menu-item">Real Estate <span class="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></a></li>
                                <li [ngClass]="activeManu === '/index-consulting' ? 'active' : ''"><a routerLink="/index-consulting" class="sub-menu-item">Consulting </a></li>
                                <li [ngClass]="activeManu === '/index-life-coach' ? 'active' : ''"><a routerLink="/index-life-coach" class="sub-menu-item">Life Coach <span class="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></a></li>
                                <li [ngClass]="activeManu === '/index-insurance' ? 'active' : ''"><a routerLink="/index-insurance" class="sub-menu-item">Insurance </a></li>
                                <li [ngClass]="activeManu === '/index-construction' ? 'active' : ''"><a routerLink="/index-construction" class="sub-menu-item">Construction </a></li>
                                <li [ngClass]="activeManu === '/index-law' ? 'active' : ''"><a routerLink="/index-law" class="sub-menu-item">Law Firm </a></li>
                                <li [ngClass]="activeManu === '/index-video' ? 'active' : ''"><a routerLink="/index-video" class="sub-menu-item">Video </a></li>
                                <li [ngClass]="activeManu === '/index-christmas' ? 'active' : ''"><a routerLink="/index-christmas" class="sub-menu-item">Christmas</a></li>
                            </ul>
                        </li>
                
                        <li>
                            <ul>
                                <li [ngClass]="activeManu === '/index-personal' ? 'active' : ''"><a routerLink="/index-personal" class="sub-menu-item">Personal</a></li>
                                <li [ngClass]="activeManu === '/index-portfolio' ? 'active' : ''"><a routerLink="/index-portfolio" class="sub-menu-item">Portfolio <span class="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></a></li>
                                <li [ngClass]="activeManu === '/index-photography' ? 'active' : ''"><a routerLink="/index-photography" class="sub-menu-item">Photography <span class="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></a></li>
                                <li [ngClass]="activeManu === '/index-studio' ? 'active' : ''"><a routerLink="/index-studio" class="sub-menu-item">Studio</a></li>
                                <li [ngClass]="activeManu === '/index-coworking' ? 'active' : ''"><a routerLink="/index-coworking" class="sub-menu-item">Co-Woriking</a></li>
                                <li [ngClass]="activeManu === '/index-index-classic-business' ? 'active' : ''"><a routerLink="/index-classic-business" class="sub-menu-item">Classic Business</a></li>
                                <li [ngClass]="activeManu === '/index-course' ? 'active' : ''"><a routerLink="/index-course" class="sub-menu-item">Online Courses </a></li>
                                <li [ngClass]="activeManu === '/index-event' ? 'active' : ''"><a routerLink="/index-event" class="sub-menu-item">Event / Conference </a></li>
                                <li [ngClass]="activeManu === '/index-podcast' ? 'active' : ''"><a routerLink="/index-podcast" class="sub-menu-item">Podcasts </a></li>
                                <li [ngClass]="activeManu === '/index-hospital' ? 'active' : ''"><a routerLink="/index-hospital" class="sub-menu-item">Hospital</a></li>
                                <li [ngClass]="activeManu === '/index-phone-number' ? 'active' : ''"><a routerLink="/index-phone-number" class="sub-menu-item">Phone Number</a></li>
                                <li [ngClass]="activeManu === '/index-forums' ? 'active' : ''"><a routerLink="/index-forums" class="sub-menu-item">Forums </a></li>
                            </ul>
                        </li>
                
                        <li>
                            <ul>
                               <li [ngClass]="activeManu === '/index-shop' ? 'active' : ''"><a routerLink="/index-shop" class="sub-menu-item">Shop / eCommerce <span class="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></a></li>
                                <li [ngClass]="activeManu === '/index-crypto' ? 'active' : ''"><a routerLink="/index-crypto" class="sub-menu-item">Cryptocurrency  <span class="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></a></li>
                                <li [ngClass]="activeManu === '/index-landing-one' ? 'active' : ''"><a routerLink="/index-landing-one" class="sub-menu-item">Landing One</a></li>
                                <li [ngClass]="activeManu === '/index-landing-two' ? 'active' : ''"><a routerLink="/index-landing-two" class="sub-menu-item">Landing Two</a></li>
                                <li [ngClass]="activeManu === '/index-landing-three' ? 'active' : ''"><a routerLink="/index-landing-three" class="sub-menu-item">Landing Three</a></li>
                                <li [ngClass]="activeManu === '/index-landing-four' ? 'active' : ''"><a routerLink="/index-landing-four" class="sub-menu-item">Landing Four</a></li>
                                <li [ngClass]="activeManu === '/index-landing-six' ? 'active' : ''"><a routerLink="/index-landing-six" class="sub-menu-item">Landing Six <span class="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></a></li>
                                <li [ngClass]="activeManu === '/index-service' ? 'active' : ''"><a routerLink="/index-service" class="sub-menu-item">Service Provider</a></li>
                                <li [ngClass]="activeManu === '/index-food-blog' ? 'active' : ''"><a routerLink="/index-food-blog" class="sub-menu-item">Food Blog </a></li>
                                <li [ngClass]="activeManu === '/index-blog' ? 'active' : ''"><a routerLink="/index-blog" class="sub-menu-item">Blog </a></li>
                                <li [ngClass]="activeManu === '/index-furniture' ? 'active' : ''"><a routerLink="/index-furniture" class="sub-menu-item">Furniture </a></li>
                                <li [ngClass]="activeManu === '/index-landing-five' ? 'active' : ''"><a routerLink="/index-landing-five" class="sub-menu-item">Landing Five <span class="bg-green-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Onepage</span></a></li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="has-submenu parent-parent-menu-item" [ngClass]="['/page-aboutus', '/page-services', '/page-team', '/page-pricing','/page-testimonial','/user-profile','/user-billing','/user-payment','/user-invoice','/user-social','/user-notification','/user-setting','/property-listing','/property-detail','/course-listing','/course-detail','/nft-explore','/nft-auction','/nft-collection','/nft-creators','/nft-creator-profile','/nft-creator-profile-edit','/nft-wallet','/nft-create-item','/nft-detail','/food-recipe','/shop-grid','/shop-grid-two','/shop-item-detail','/shop-cart','/shop-checkout','/shop-account','/photography-about','/photography-portfolio','/page-job-grid','/page-job-detail','/page-job-apply','/page-job-post','/page-job-career','/page-job-candidates','/page-job-candidate-detail','/page-job-companies','/page-job-company-detail','/forums-topic','/forums-comments','/helpcenter','/helpcenter-faqs','/helpcenter-guides','/helpcenter-support','/blog','/blog-sidebar','/blog-detail','/blog-standard-post','/blog-slider-post','/blog-gallery-post','/blog-youtube-post','/blog-vimeo-post','/blog-audio-post','/blog-blockquote-post','/blog-left-sidebar-post','/email-confirmation','/email-password-reset','/email-alert','email-invoice','/auth-login','/auth-signup','/auth-re-password','/auth-lock-screen','/page-terms', '/page-privacy','/page-comingsoon','/page-maintenance','/page-error','/page-thankyou','/contact-detail','/contact-one','/contact-two'].includes(activeManu) ? 'active' : '' ">
                    <a routerLink="#" (click)="subManu('/page-item')">Pages</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/page-aboutus', '/page-services', '/page-team', '/page-pricing','/page-testimonial','/user-profile','/user-billing','/user-payment','/user-invoice','/user-social','/user-notification','/user-setting','/property-listing','/property-detail','/course-listing','/course-detail','/nft-explore','/nft-auction','/nft-collection','/nft-creators','/nft-creator-profile','/nft-creator-profile-edit','/nft-wallet','/nft-create-item','/nft-detail','/food-recipe','/shop-grid','/shop-grid-two','/shop-item-detail','/shop-cart','/shop-checkout','/shop-account','/photography-about','/photography-portfolio','/page-job-grid','/page-job-detail','/page-job-apply','/page-job-post','/page-job-career','/page-job-candidates','/page-job-candidate-detail','/page-job-companies','/page-job-company-detail','/forums-topic','/forums-comments','/helpcenter','/helpcenter-faqs','/helpcenter-guides','/helpcenter-support','/blog','/blog-sidebar','/blog-detail','/blog-standard-post','/blog-slider-post','/blog-gallery-post','/blog-youtube-post','/blog-vimeo-post','/blog-audio-post','/blog-blockquote-post','/blog-left-sidebar-post','/email-confirmation','/email-password-reset','/email-alert','email-invoice','/auth-login','/auth-signup','/auth-re-password','/auth-lock-screen','/page-terms', '/page-privacy','/page-comingsoon','/page-maintenance','/page-error','/page-thankyou','/contact-detail','/contact-one','/contact-two','/company-item','/account-item','/estate-item','/course-item','/nft-item','/shop-item','/photo-item','/job-item','/forums-item','/help-item','/blog-item','/mail-item','/auth-item','/utility-item','/special-item', '/contact-item','/multi-item','/page-item','/creator-item','/blog-post-item','/multi-item','/multi-item-two'].includes(manuOpen) ? 'open' : '' ">
                        <li class="has-submenu parent-menu-item" [ngClass]="['/page-aboutus','/page-services', '/page-team','/page-pricing','/page-testimonial'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/company-item')"> Company </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/company-item','/page-aboutus','/page-services','/page-team','/page-pricing','/page-testimonial'].includes(manuOpen) ? 'open' : '' ">
                                <li [ngClass]="activeManu === '/page-aboutus' ? 'active' : ''"><a routerLink="/page-aboutus" class="sub-menu-item"> About Us</a></li>
                                <li [ngClass]="activeManu === '/page-services' ? 'active' : ''"><a routerLink="/page-services" class="sub-menu-item">Services</a></li>
                                <li [ngClass]="activeManu === '/page-team' ? 'active' : ''"><a routerLink="/page-team" class="sub-menu-item"> Team</a></li>
                                <li [ngClass]="activeManu === '/page-pricing' ? 'active' : ''"><a routerLink="/page-pricing" class="sub-menu-item">Pricing</a></li>
                                <li  [ngClass]="activeManu === '/page-testimonial' ? 'active' : ''"><a routerLink="/page-testimonial" class="sub-menu-item">Testimonial </a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/user-profile','/user-billing', '/user-payment','/user-invoice','/user-social','/user-notification','/user-setting'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/account-item')"> Accounts</a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/account-item', '/user-profile', '/user-billing','/user-payment','/user-invoice','/user-social', '/user-notification','/user-setting'].includes(manuOpen) ? 'open' : '' ">
                                <li [ngClass]="activeManu === '/user-profile' ? 'active' : ''"><a routerLink="/user-profile" class="sub-menu-item">User Profile</a></li>
                                <li [ngClass]="activeManu === '/user-billing' ? 'active' : ''"><a routerLink="/user-billing" class="sub-menu-item">Billing</a></li>
                                <li [ngClass]="activeManu === '/user-payment' ? 'active' : ''"><a routerLink="/user-payment" class="sub-menu-item">Payment</a></li>
                                <li [ngClass]="activeManu === '/user-invoice' ? 'active' : ''"><a routerLink="/user-invoice" class="sub-menu-item">Invoice</a></li>
                                <li [ngClass]="activeManu === '/user-social' ? 'active' : ''"><a routerLink="/user-social" class="sub-menu-item">Social</a></li>
                                <li [ngClass]="activeManu === '/user-notification' ? 'active' : ''"><a routerLink="/user-notification" class="sub-menu-item">Notification</a></li>
                                <li [ngClass]="activeManu === '/user-setting' ? 'active' : ''"><a routerLink="/user-setting" class="sub-menu-item">Setting</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/property-listing', '/property-detail'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/estate-item')"> Real Estate</a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/estate-item', '/property-listing', '/property-detail'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/property-listing' ? 'active' : ''"><a routerLink="/property-listing" class="sub-menu-item">Listing</a></li>
                                <li [ngClass]="activeManu === '/property-detail' ? 'active' : ''"><a routerLink="/property-detail" class="sub-menu-item">Property Detail</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="[ '/course-listing', '/course-detail'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/course-item')"> Courses </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/course-item', '/course-listing', '/course-detail'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/course-listing' ? 'active' : ''"><a routerLink="/course-listing" class="sub-menu-item">Course Listing</a></li>
                                <li [ngClass]="activeManu === '/course-detail' ? 'active' : ''"><a routerLink="/course-detail" class="sub-menu-item">Course Detail</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="[ '/nft-item', '/nft-explore', '/nft-auction', '/nft-collection','/nft-creators','/nft-creator-profile','/nft-creator-profile-edit','/nft-wallet','/nft-create-item','/nft-detail','/creator-item'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/nft-item')"> NFT Market </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/nft-item', '/nft-explore', '/nft-auction', '/nft-collection','/nft-creators','/nft-creator-profile','/nft-creator-profile-edit','/nft-wallet','/nft-create-item','/nft-detail','/creator-item'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/nft-explore' ? 'active' : ''"><a routerLink="/nft-explore" class="sub-menu-item">Explore</a></li>
                                <li [ngClass]="activeManu === '/nft-auction' ? 'active' : ''"><a routerLink="/nft-auction" class="sub-menu-item">Auction</a></li>
                                <li [ngClass]="activeManu === '/nft-collection' ? 'active' : ''"><a routerLink="/nft-collection" class="sub-menu-item">Collections</a></li>
                                <li class="has-submenu child-menu-item" [ngClass]="[ '/nft-creators', '/nft-creator-profile','/nft-creator-profile-edit'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/creator-item')"> Creator  </a><span class="submenu-arrow"></span>
                                    <ul class="submenu" [ngClass]="['/creator-item', '/nft-creators', '/nft-creator-profile','/nft-creator-profile-edit'].includes(manuOpen) ? 'open' :'' ">
                                        <li [ngClass]="activeManu === '/nft-creators' ? 'active' : ''"><a routerLink="/nft-creators" class="sub-menu-item"> Creators</a></li>
                                        <li [ngClass]="activeManu === '/nft-creators' ? 'active' : ''"><a routerLink="/nft-creators" class="sub-menu-item"> Creator Profile </a></li>
                                        <li [ngClass]="activeManu === '/nft-creator-profile-edit' ? 'active' : ''"><a routerLink="/nft-creator-profile-edit" class="sub-menu-item"> Profile Edit </a></li>
                                    </ul>  
                                </li>
                                <li [ngClass]="activeManu === '/nft-wallet' ? 'active' : ''"><a routerLink="/nft-wallet" class="sub-menu-item">Wallet</a></li>
                                <li [ngClass]="activeManu === '/nft-create-item' ? 'active' : ''"><a routerLink="/nft-create-item" class="sub-menu-item">Create NFT</a></li>
                                <li [ngClass]="activeManu === '/nft-detail' ? 'active' : ''"><a routerLink="/nft-detail" class="sub-menu-item">Single NFT</a></li>
                            </ul> 
                        </li>
                        <li [ngClass]="activeManu === '/food-recipe' ? 'active' : ''"><a routerLink="/food-recipe" class="sub-menu-item">Food Recipe </a></li>
                        <li class="has-submenu parent-menu-item" [ngClass]="[ '/shop-grid', '/shop-grid-two','/shop-item-detail','/shop-cart','/shop-checkout','/shop-account'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/shop-item')"> eCommerce </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/shop-item', '/shop-grid', '/shop-grid-two','/shop-item-detail','/shop-cart','/shop-checkout','/shop-account'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/shop-grid' ? 'active' : ''"><a routerLink="/shop-grid" class="sub-menu-item">Product Grid</a></li>
                                <li [ngClass]="activeManu === '/shop-grid' ? 'active' : ''"><a routerLink="/shop-grid" class="sub-menu-item">Product Grid Two</a></li>
                                <li [ngClass]="activeManu === '/shop-item-detail' ? 'active' : ''"><a routerLink="/shop-item-detail" class="sub-menu-item">Product Detail</a></li>
                                <li [ngClass]="activeManu === '/shop-cart' ? 'active' : ''"><a routerLink="/shop-cart" class="sub-menu-item">Shop Cart</a></li>
                                <li [ngClass]="activeManu === '/shop-checkout' ? 'active' : ''"><a routerLink="/shop-checkout" class="sub-menu-item">Checkout</a></li>
                                <li [ngClass]="activeManu === '/shop-account' ? 'active' : ''"><a routerLink="/shop-account" class="sub-menu-item">My Account</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="[ '/shop-grid', '/photography-about', '/photography-portfolio'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/photo-item')"> Photography </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/photo-item', '/photography-about', '/photography-portfolio'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/photography-about' ? 'active' : ''"><a routerLink="/photography-about" class="sub-menu-item">About Us</a></li>
                                <li [ngClass]="activeManu === '/photography-portfolio' ? 'active' : ''"><a routerLink="/photography-portfolio" class="sub-menu-item">Portfolio</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/page-job-grid', '/page-job-detail','/page-job-apply', '/page-job-post', '/page-job-career','page-job-candidates','/page-job-candidate-detail','/page-job-companies','/page-job-company-detail'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/job-item')"> Job / Careers </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/job-item', '/page-job-grid', '/page-job-detail','/page-job-apply', '/page-job-post', '/page-job-career','page-job-candidates','/page-job-candidate-detail','/page-job-companies','/page-job-company-detail'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/page-job-grid' ? 'active' : ''"><a routerLink="/page-job-grid" class="sub-menu-item">All Jobs</a></li>
                                <li [ngClass]="activeManu === '/page-job-detail' ? 'active' : ''"><a routerLink="/page-job-detail" class="sub-menu-item">Job Detail</a></li>
                                <li [ngClass]="activeManu === '/page-job-apply' ? 'active' : ''"><a routerLink="/page-job-apply" class="sub-menu-item">Job Apply</a></li>
                                <li [ngClass]="activeManu === '/page-job-post' ? 'active' : ''"><a routerLink="/page-job-post" class="sub-menu-item">Job Post </a></li>
                                <li [ngClass]="activeManu === '/page-job-career' ? 'active' : ''"><a routerLink="/page-job-career" class="sub-menu-item">Job Career </a></li>
                                <li [ngClass]="activeManu === '/page-job-candidates' ? 'active' : ''"><a routerLink="/page-job-candidates" class="sub-menu-item">Job Candidates</a></li>
                                <li [ngClass]="activeManu === '/page-job-candidate-detail' ? 'active' : ''"><a routerLink="/page-job-candidate-detail" class="sub-menu-item">Candidate Detail</a></li>
                                <li [ngClass]="activeManu === '/page-job-companies' ? 'active' : ''"><a routerLink="/page-job-companies" class="sub-menu-item">All Companies</a></li>
                                <li [ngClass]="activeManu === '/page-job-company-detail' ? 'active' : ''"><a routerLink="/page-job-company-detail" class="sub-menu-item">Company Detail</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="[ '/forums-topic', '/forums-comments'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/forums-item')"> Forums </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/forums-item', '/forums-topic', '/forums-comments'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/forums-topic' ? 'active' : ''"><a routerLink="/forums-topic" class="sub-menu-item">Forum Topic</a></li>
                                <li [ngClass]="activeManu === '/forums-comments' ? 'active' : ''"><a routerLink="/forums-comments" class="sub-menu-item">Forum Comments</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="[ '/helpcenter', '/helpcenter-faqs', 'helpcenter-guides','/helpcenter-support'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/help-item')"> Helpcenter </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/help-item', '/helpcenter', '/helpcenter-faqs', 'helpcenter-guides','/helpcenter-support'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/helpcenter' ? 'active' : ''"><a routerLink="/helpcenter" class="sub-menu-item">Overview</a></li>
                                <li [ngClass]="activeManu === '/helpcenter-faqs' ? 'active' : ''"><a routerLink="/helpcenter-faqs" class="sub-menu-item">FAQs</a></li>
                                <li [ngClass]="activeManu === '/helpcenter-guides' ? 'active' : ''"><a routerLink="/helpcenter-guides" class="sub-menu-item">Guides</a></li>
                                <li [ngClass]="activeManu === '/helpcenter-support' ? 'active' : ''"><a routerLink="/helpcenter-support" class="sub-menu-item">Support</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/blog-item', '/blog', '/blog-sidebar', '/blog-detail','/blog-standard-post', '/blog-slider-post','/blog-gallery-post','/blog-youtube-post','/blog-vimeo-post','/blog-audio-post','/blog-blockquote-post','/blog-left-sidebar-post','/blog-post-item'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/blog-item')"> Blog </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/blog-item', '/blog', '/blog-sidebar', '/blog-detail','/blog-standard-post', '/blog-slider-post','/blog-gallery-post','/blog-youtube-post','/blog-vimeo-post','/blog-audio-post','/blog-blockquote-post','/blog-left-sidebar-post','/blog-post-item'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/blog' ? 'active' : ''"><a routerLink="/blog" class="sub-menu-item">Blogs</a></li>
                                <li [ngClass]="activeManu === '/blog-sidebar' ? 'active' : ''"><a routerLink="/blog-sidebar" class="sub-menu-item">Blogs & Sidebar</a></li>
                                <li [ngClass]="activeManu === '/blog-detail' ? 'active' : ''"><a routerLink="/blog-detail" class="sub-menu-item">Blog Detail</a></li>
                                <li class="has-submenu child-menu-item" [ngClass]="['/blog-post-item','/blog-standard-post', '/blog-slider-post','/blog-gallery-post','/blog-youtube-post','/blog-vimeo-post','/blog-audio-post','/blog-blockquote-post','/blog-left-sidebar-post','/blog-post-item'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/blog-post-item')"> Blog Posts </a><span class="submenu-arrow"></span>
                                    <ul class="submenu" [ngClass]="['/blog-post-item','/blog-standard-post', '/blog-slider-post','/blog-gallery-post','/blog-youtube-post','/blog-vimeo-post','/blog-audio-post','/blog-blockquote-post','/blog-left-sidebar-post','/blog-post-item'].includes(manuOpen) ? 'open' :'' ">
                                        <li [ngClass]="activeManu === '/blog-standard-post' ? 'active' : ''"><a routerLink="/blog-standard-post" class="sub-menu-item">Standard Post</a></li>
                                        <li [ngClass]="activeManu === '/blog-slider-post' ? 'active' : ''"><a routerLink="/blog-slider-post" class="sub-menu-item">Slider Post</a></li>
                                        <li [ngClass]="activeManu === '/blog-gallery-post' ? 'active' : ''"><a routerLink="/blog-gallery-post" class="sub-menu-item">Gallery Post</a></li>
                                        <li [ngClass]="activeManu === '/blog-youtube-post' ? 'active' : ''"><a routerLink="/blog-youtube-post" class="sub-menu-item">Youtube Post</a></li>
                                        <li [ngClass]="activeManu === '/blog-vimeo-post' ? 'active' : ''"><a routerLink="/blog-vimeo-post" class="sub-menu-item">Vimeo Post</a></li>
                                        <li [ngClass]="activeManu === '/blog-audio-post' ? 'active' : ''"><a routerLink="/blog-audio-post" class="sub-menu-item">Audio Post</a></li>
                                        <li [ngClass]="activeManu === '/blog-blockquote-post' ? 'active' : ''"><a routerLink="/blog-blockquote-post" class="sub-menu-item">Blockquote</a></li>
                                        <li [ngClass]="activeManu === '/blog-left-sidebar-post' ? 'active' : ''"><a routerLink="/blog-left-sidebar-post" class="sub-menu-item">Left Sidebar</a></li>
                                    </ul>  
                                </li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/mail-item', '/email-confirmation','/email-password-reset','/email-alert','/email-invoice'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/mail-item')"> Email Template</a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/mail-item', '/email-confirmation','/email-password-reset','/email-alert','/email-invoice'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/email-confirmation' ? 'active' : ''"><a routerLink="/email-confirmation" class="sub-menu-item">Confirmation</a></li>
                                <li [ngClass]="activeManu === '/email-password-reset' ? 'active' : ''"><a routerLink="/email-password-reset" class="sub-menu-item">Reset Password</a></li>
                                <li [ngClass]="activeManu === '/email-alert' ? 'active' : ''"><a routerLink="/email-alert" class="sub-menu-item">Alert</a></li>
                                <li [ngClass]="activeManu === '/email-invoice' ? 'active' : ''"><a routerLink="/email-invoice" class="sub-menu-item">Invoice</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/auth-item', '/auth-login','/auth-signup','/auth-re-password','/auth-lock-screen'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/auth-item')"> Auth Pages </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/auth-item', '/auth-login','/auth-signup','/auth-re-password','/auth-lock-screen'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/auth-login' ? 'active' : ''"><a routerLink="/auth-login" class="sub-menu-item">Login</a></li>
                                <li [ngClass]="activeManu === '/auth-signup' ? 'active' : ''"><a routerLink="/auth-signup" class="sub-menu-item">Signup</a></li>
                                <li [ngClass]="activeManu === '/auth-re-password' ? 'active' : ''"><a routerLink="/auth-re-password" class="sub-menu-item">Reset Password</a></li>
                                <li [ngClass]="activeManu === '/auth-lock-screen' ? 'active' : ''"><a routerLink="/auth-lock-screen" class="sub-menu-item">Lock Screen</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/utility-item', '/page-terms','/page-privacy'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/utility-item')"> Utility </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/utility-item', '/page-terms','/page-privacy'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/page-terms' ? 'active' : ''"><a routerLink="/page-terms" class="sub-menu-item">Terms of Services</a></li>
                                <li [ngClass]="activeManu === '/page-privacy' ? 'active' : ''"><a routerLink="/page-privacy" class="sub-menu-item">Privacy Policy</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/special-item', '/page-comingsoon','/page-maintenance', '/page-error','/page-thankyou'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/special-item')"> Special</a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/special-item', '/page-comingsoon','/page-maintenance', '/page-error','/page-thankyou'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/page-comingsoon' ? 'active' : ''"><a routerLink="/page-comingsoon" class="sub-menu-item">Coming Soon</a></li>
                                <li [ngClass]="activeManu === '/page-maintenance' ? 'active' : ''"><a routerLink="/page-maintenance" class="sub-menu-item">Maintenance</a></li>
                                <li [ngClass]="activeManu === '/page-error' ? 'active' : ''"><a routerLink="/page-error" class="sub-menu-item">Error</a></li>
                                <li [ngClass]="activeManu === '/page-thankyou' ? 'active' : ''"><a routerLink="/page-thankyou" class="sub-menu-item">Thank you</a></li>
                            </ul>
                        </li>
                        <li class="has-submenu parent-menu-item" [ngClass]="['/contact-item', '/contact-detail','/contact-one','/contact-two'].includes(activeManu) ? 'active' : '' "><a routerLink="#" (click)="subManu('/contact-item')"> Contact </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/contact-item', '/contact-detail','/contact-one','/contact-two'].includes(manuOpen) ? 'open' :'' ">
                                <li [ngClass]="activeManu === '/contact-detail' ? 'active' : ''"><a routerLink="/contact-detail" class="sub-menu-item">Contact Detail</a></li>
                                <li [ngClass]="activeManu === '/contact-one' ? 'active' : ''"><a routerLink="/contact-one" class="sub-menu-item">Contact One</a></li>
                                <li [ngClass]="activeManu === '/contact-two' ? 'active' : ''"><a routerLink="/contact-two" class="sub-menu-item">Contact Two</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item"><a routerLink="#" (click)="subManu('/multi-item')"> Multi Level Menu</a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['/multi-item','/multi-item-two'].includes(manuOpen) ? 'open' :'' ">
                                <li><a routerLink="#" class="sub-menu-item">Level 1.0</a></li>
                                <li class="has-submenu child-menu-item"><a routerLink="#" (click)="subManu('/multi-item-two')"> Level 2.0 </a><span class="submenu-arrow"></span>
                                    <ul class="submenu" [ngClass]="[ '/multi-item-two'].includes(manuOpen) ? 'open' :'' ">
                                        <li><a routerLink="#" class="sub-menu-item">Level 2.1</a></li>
                                        <li><a routerLink="#" class="sub-menu-item">Level 2.2</a></li>
                                    </ul>  
                                </li>
                            </ul>  
                        </li>
                    </ul>
                </li>

                <li class="has-submenu parent-parent-menu-item" [ngClass]="['/portfolio-modern-two', '/portfolio-modern-three','/portfolio-modern-four','/portfolio-modern-five', '/portfolio-modern-six','/portfolio-classic-two','/portfolio-classic-three','/portfolio-classic-four','/portfolio-classic-five','/portfolio-classic-six','/portfolio-creative-two','/portfolio-creative-three','/portfolio-creative-four','/portfolio-creative-five','/portfolio-creative-six','/portfolio-masonry-two','/portfolio-masonry-three','/portfolio-masonry-four','/portfolio-masonry-five','/portfolio-masonry-six','/portfolio-detail-one','/portfolio-detail-two','/portfolio-detail-three','/portfolio-detail-four'].includes(activeManu) ? 'active' : '' ">
                    <a routerLink="#" (click)="subManu('/portfolio-item')">Portfolio</a><span class="menu-arrow"></span>
                    <ul class="submenu megamenu" [ngClass]="['/portfolio-item','/portfolio-modern-two', '/portfolio-modern-three','/portfolio-modern-four','/portfolio-modern-five', '/portfolio-modern-six','/portfolio-classic-two','/portfolio-classic-three','/portfolio-classic-four','/portfolio-classic-five','/portfolio-classic-six','/portfolio-creative-two','/portfolio-creative-three','/portfolio-creative-four','/portfolio-creative-five','/portfolio-creative-six','/portfolio-masonry-two','/portfolio-masonry-three','/portfolio-masonry-four','/portfolio-masonry-five','/portfolio-masonry-six','/portfolio-detail-one','/portfolio-detail-two','/portfolio-detail-three','/portfolio-detail-four'].includes(manuOpen) ? 'open' :'' ">
                        <li>
                            <ul>
                                <li class="megamenu-head">Modern Portfolio</li>
                                <li [ngClass]="activeManu === '/portfolio-modern-two' ? 'active' : ''"><a routerLink="/portfolio-modern-two" class="sub-menu-item">Two Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-modern-two' ? 'active' : ''"><a routerLink="/portfolio-modern-two" class="sub-menu-item">Three Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-modern-four' ? 'active' : ''"><a routerLink="/portfolio-modern-four" class="sub-menu-item">Four Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-modern-five' ? 'active' : ''"><a routerLink="/portfolio-modern-five" class="sub-menu-item">Five Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-modern-six' ? 'active' : ''"><a routerLink="/portfolio-modern-six" class="sub-menu-item">Six Column</a></li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li class="megamenu-head">Classic Portfolio</li>
                                <li [ngClass]="activeManu === '/portfolio-classic-two' ? 'active' : ''"><a routerLink="/portfolio-classic-two" class="sub-menu-item">Two Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-classic-three' ? 'active' : ''"><a routerLink="/portfolio-classic-three" class="sub-menu-item">Three Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-classic-four' ? 'active' : ''"><a routerLink="/portfolio-classic-four" class="sub-menu-item">Four Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-classic-five' ? 'active' : ''"><a routerLink="/portfolio-classic-five" class="sub-menu-item">Five Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-classic-six' ? 'active' : ''"><a routerLink="/portfolio-classic-six" class="sub-menu-item">Six Column</a></li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li class="megamenu-head">Creative Portfolio</li>
                                <li [ngClass]="activeManu === '/portfolio-creative-two' ? 'active' : ''"><a routerLink="/portfolio-creative-two" class="sub-menu-item">Two Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-creative-three' ? 'active' : ''"><a routerLink="/portfolio-creative-three" class="sub-menu-item">Three Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-creative-four' ? 'active' : ''"><a routerLink="/portfolio-creative-four" class="sub-menu-item">Four Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-creative-five' ? 'active' : ''"><a routerLink="/portfolio-creative-five" class="sub-menu-item">Five Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-creative-six' ? 'active' : ''"><a routerLink="/portfolio-creative-six" class="sub-menu-item">Six Column</a></li>
                            </ul>
                        </li>
                
                        <li>
                            <ul>
                                <li class="megamenu-head">Masonry Portfolio</li>
                                <li [ngClass]="activeManu === '/portfolio-masonry-two' ? 'active' : ''"><a routerLink="/portfolio-masonry-two" class="sub-menu-item">Two Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-masonry-three' ? 'active' : ''"><a routerLink="/portfolio-masonry-three" class="sub-menu-item">Three Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-masonry-four' ? 'active' : ''"><a routerLink="/portfolio-masonry-four" class="sub-menu-item">Four Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-masonry-five' ? 'active' : ''"><a routerLink="/portfolio-masonry-five" class="sub-menu-item">Five Column</a></li>
                                <li [ngClass]="activeManu === '/portfolio-masonry-six' ? 'active' : ''"><a routerLink="/portfolio-masonry-six" class="sub-menu-item">Six Column</a></li>
                            </ul>
                        </li>
                
                        <li>
                            <ul>
                                <li class="megamenu-head">Portfolio Detail</li>
                                <li [ngClass]="activeManu === '/portfolio-detail-one' ? 'active' : ''"><a routerLink="/portfolio-detail-one" class="sub-menu-item">Portfolio One</a></li>
                                <li [ngClass]="activeManu === '/portfolio-detail-two' ? 'active' : ''"><a routerLink="/portfolio-detail-two" class="sub-menu-item">Portfolio Two</a></li>
                                <li [ngClass]="activeManu === '/portfolio-detail-three' ? 'active' : ''"><a routerLink="/portfolio-detail-three" class="sub-menu-item">Portfolio Three</a></li>
                                <li [ngClass]="activeManu === '/portfolio-detail-four' ? 'active' : ''"><a routerLink="/portfolio-detail-four" class="sub-menu-item">Portfolio Four</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="has-submenu parent-menu-item" [ngClass]="['/ui-components', '/documentation','/changelog','/widget','/docs-item' ].includes(activeManu) ? 'active' : '' ">
                    <a routerLink="#" (click)="subManu('/docs-item')">Docs</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/ui-components', '/documentation','/changelog','/widget','/docs-item' ].includes(manuOpen) ? 'open' :'' ">
                        <li [ngClass]="activeManu === '/ui-components' ? 'active' : ''"><a routerLink="/ui-components" class="sub-menu-item">Components </a></li>
                        <li [ngClass]="activeManu === '/documentation' ? 'active' : ''"><a routerLink="/documentation" class="sub-menu-item">Documentation</a></li>
                        <li [ngClass]="activeManu === '/changelog' ? 'active' : ''"><a routerLink="/changelog" class="sub-menu-item">Changelog</a></li>
                    </ul>
                </li> -->

            </ul><!--end navigation menu-->
        </div><!--end navigation-->
    </div><!--end container-->
</nav>
