import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwitcherComponent } from './switcher/switcher.component';
import * as feather from 'feather-icons';
import { environment } from '../environments/environment';
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SwitcherComponent],
  selector: 'cricteams-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cricteams';
  ngAfterViewInit() {
    feather.replace();
  }
  ngOnInit() {
    this.loadRecaptchaScript();
  }
  private loadRecaptchaScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaSiteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
}
