import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BackToHomeComponent } from '../../components/back-to-home/back-to-home.component';

@Component({
  selector: 'cricteams-app-auth-login',
  standalone: true,
  imports: [BackToHomeComponent,RouterLink],
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent {

}
